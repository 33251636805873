@use 'styles/variables';
@use 'styles/mixins';
@use 'styles/functions';

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;

  >div {
    display: block;
  }
}

.switchWrapper {
  margin-bottom: functions.rem(12);
}

.switches {
  display: flex;
  justify-content: space-between;
}

.image {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}