.print {
  .logo-wrapper {
    text-align: right;
  }
  .logo-wrapper img {
    width: 150px;
  }
  .logo {
    margin: 0;
    margin-left: auto;
  }
  .measurments {
    text-align: left;
  }
  .measurments img {
    width: 70%;
  }
  h1.header {
    margin: 0;
    text-align: left;
    color: black;
    font-weight: bold;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 0;
  }
  h1.subheader {
    margin: 0;
    text-align: left;
    font-weight: normal;
    font-style: italic;
    border-bottom: 2px solid black;
    color: black;
    padding-bottom: 20px;

    padding-left: 50px;
    padding-right: 50px;
  }

  .wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }

  .item a {
    text-decoration: none;
  }
}