@use './variables';
@use './mixins';
@use './functions';

@import "css-variables";

// @import-normalize;

@import "@csstools/normalize.css";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Roboto:wght@400;500;700&display=swap');

@import "print";

/**
 * @PROJECT
 * This is a project specific import. When switching projects, look for every import that is marked with @PROJECT
 */
@import "@hydrapark/styles/styles";

html {
  box-sizing: border-box;
  line-height: 1.47;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-weight: var(--font-weight-regular, variables.$font-weight-regular);
  font-size: functions.rem(12);
  font-family: var(--font-family-primary, variables.$font-family-primary);
}

h1 {
  @include mixins.h1();
  text-align: center;
  color: var(--header-color-code, variables.$primary);
  font-family: var(--font-family-header, variables.$font-family-primary);
}

h2 {
  @include mixins.h2();
  font-family: var(--font-family-header, variables.$font-family-primary);
}

h3 {
  @include mixins.h3();
  font-family: var(--font-family-header, variables.$font-family-primary);
}

h4 {
  @include mixins.h4();
  font-family: var(--font-family-header, variables.$font-family-primary);
}

h5 {
  @include mixins.h5();
  font-family: var(--font-family-header, variables.$font-family-primary);
}