@use 'styles/variables';
@use 'styles/mixins';
@use 'styles/functions';

.secondaryNav {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @include mixins.heightSmall {
    gap: 0;
  }
}
