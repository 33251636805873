@use 'styles/variables';
@use 'styles/mixins';
@use 'styles/functions';

.radioButton {
  @include mixins.radioButton();

  margin-top: functions.rem(12);
}

.description {
  opacity: .78;
  line-height: functions.rem(18);
}

.length {
  font-family: var(--font--family-secondary, variables.$font-family-secondary);
  font-weight: var(--font--weight-semi-bold, variables.$font-weight-semi-bold);
}

.image {
  cursor: pointer;
  display: block;
  border-radius: 10px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05);
  aspect-ratio: 1;
  width: 100%;
  background-position: 50% 50%;
  background-size: contain;
}

[class*="input"]:checked + div .image {
  box-shadow: 0 0 0 2px var(--color-primary, #0075BF);
}